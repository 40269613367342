import React from 'react'
import Copy from 'components/Copy'
import Image from 'components/Image'
import Row from 'components/Row'
import Text from 'components/Text'
import GatedResource from 'templates/GatedResource'
import coverImage from './images/cover.jpg'
import cover2xImage from './images/cover@2x.jpg'

export default function ResourceCreatingExerciseRoutinesThatLast() {
  const title = 'Creating exercise routines that last'

  return (
    <GatedResource
      title={
        <>
          A Bold fitness ebook:
          <br />
          {title}
        </>
      }
      entity="ebook"
      resource={title}>
      <Row>
        <Copy>
          <Text element="p">
            Creating an exercise routine that lasts can be challenging. In this ebook we cover:
          </Text>
          <Text element="ul">
            <Text element="li">Why people don’t exercise</Text>
            <Text element="li">Common myths about exercise</Text>
            <Text element="li">5 keys for building routines that last</Text>
            <Text element="li">How to know your exercise options</Text>
          </Text>
        </Copy>
      </Row>
      <Image src={coverImage} src2x={cover2xImage} alt={`Cover of ‘${{ title }}’`} />
    </GatedResource>
  )
}
